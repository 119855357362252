body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.completion-indicator, .survey-name {
  color: #666;
}

.upload-icon {
  right: 1rem;
  top: -5%;
  position: relative;
}

.vstack > .accordion:not(:last-child) {
  /* @supports (-webkit-touch-callout: none) and (not (translate: none)) { */
      margin-bottom: 1rem !important;
  /* } */
}

.save-icon {
  color: #e3001b;

  margin-top: 2px;
}

.survey-name {
  font-weight: bold;
}

.survey-button * {
  font-weight: bold;
}
nav {
  background: white !important;
}

.surveys-wrapper .btn {
  line-height: 2.4;
}

.surveys-wrapper h3 {

  color: #666;

}
.btn:not(.btn-light) {
  border-radius: 30px !important;
}
.btn-primary {
  color: #fff;
    background-color: #b6192b !important;
    border-color: #b6192b !important;
}
.btn-outline-primary {
  border-color: #b6192b !important;
  color: #b6192b !important;
}

.btn-outline-primary:hover {
  color: #fff  !important;
  background-color: #b6192b !important;
  border-color: #b6192b !important;
}

.acc-item-section {
  margin-right: 0 !important;
  background-color: #fff;
}

.acc-item-subsection {
  background-color: #fff;
}

.acc-item-buildingItem {
  background-color: #fff;
}

.acc-item-defect {
  background-color: #fff;
    border: none !important;
}

.accordion-item, .accordion-item .accordion-button {
  border-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    color: #222 !important;
}

.text-warning-field {
  color: rgb(167, 11, 11) !important;
  font-size: 1.6em;
}

.accordion-item {
  margin-right: 5px;
}

.navbar {
  box-shadow: -2px 1px 8px -1px rgb(0 0 0 / 5%), -2px 1px 10px -1px rgb(0 0 0 / 12%);
}
.surveys-wrapper{
  max-width: 768px;
}
.surveys-list {
  width:100%;
  
  border-radius: 16px;
} 
.surveys-list button { 

}

.dropdown-invalid.dropdown-toggle {
  border-color: #dc3545 !important;
}

.report-button {
  /* //filter: grayscale(1); */
  background: none !important;
  color: #666666 !important;
  font-weight: bold !important;
}

.action-btn-wrap{
  border: 1.5px solid rgb(34, 33, 33);
  opacity: 0.2;
  border-radius: 24px;
  height: auto;
  width: 50%;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action-btn-wrap.active {
  opacity: 0.5;
}

.action-btn-wrap.flag.active{
  color: red;
  border: 2px solid red;
}

.action-btn-wrap.camera.active{
  color: #0982d7;
  border: 2px solid #0982d7;
}

.dropdown .dropdown-toggle {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
}

.ssuk-logo {
  height: 57px;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -10px;
}

.checkfill {
  color: #b6192b; 
  /* //cf021a */
  margin-right: 1em;
}
.checkfill.complete {
  color: rgb(38, 144, 22);
}

.checkfill.optional {
  color: #e6940ac2;
}

.modal-content {
  border-radius: 16px !important;
}

.modal-dialog {
  margin-top: 20% !important;
}

.modal.test > .modal-dialog {
  margin-top: 5% !important;
}
.lh-sm {
  line-height: 1.5;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border: none !important;
}

.form-check-input:checked[type=radio] {
  background-image: none !important;
}

.font-weight-bold.form-check label {
  font-weight: bold;
  letter-spacing: -.45px;
}

.form-check label {
  margin-left: 1em;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.accordion-button:not(.collapsed) {

}
.accordion-button:not(.collapsed).acc-button-section {
  color: #eee !important;
  background-color: #3f434a;
}
.accordion-button:not(.collapsed).acc-button-subsection {
  color: #222;
  background-color: #ededed;
  
  
}
.accordion-button:not(.collapsed).acc-button-buildingItem {
  color: #222;
  background-color: #ededed;
}
.accordion-button:not(.collapsed).acc-button-defect {
  color: #222;
  background-color: #ededed;
  
}

.cursor-pointer * {
  cursor: pointer;
}

.check-wrapper {
  min-width: 32px;
  display: flex;
}
